.cover {
  height: 120px;
}

.avatar {
  border: 2px solid white;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.3);
}

.avatarContainer {
  margin-top: -100px;
}
