.input {
  border-radius: 15px;
  padding-right: 63px !important;

  &,
  &:hover,
  &:focus {
    height: 49px;
  }
}

.mentionInput {
  border-radius: 15px;
  padding-right: 63px !important;
}

.submitButton {
  right: 0;
  height: 100%;
  min-height: 49px;
  position: absolute;
  border-radius: 0 15px 15px 0;
  background-color: transparent !important;
}
